import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import i18n from "i18next";
import axios from "axios";

function settingLanguage(lang){ 
    i18n.changeLanguage(lang);
} 
class LoginHeader extends React.Component{

constructor(props) {
    super(props);
    this.state = {
      piflogo: null, // User data
      loading: true, // Loading state
      error: null // Error state
    };
  }

  componentDidMount() {
    // Fetch user data when the component mounts
    this.fetchUser();
  }
 
  fetchUser = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/authenticationAPI/getlogo`, {
        params: { user_id: 1 } // Adjust as needed
      });
      this.setState({
        piflogo: response.data[0].Logo,
        loading: false
      });
    } catch (error) {
      this.setState({
        error: 'Error fetching user data.',
        loading: false
      });
      console.error('Error fetching user data:', error);
    }
  };

    render(){
        const { t } = this.props;
        const LoginEmail = localStorage.getItem('LoginEmail');


var pifurl1=process.env.REACT_APP_ADMIN_URL;
var pifurl=pifurl1.replace(/"/g, '');

        return (
<header className="header"> 
<nav className="navbar navbar-expand-lg header-nav " style={{'background':'#e6e6e6!important'}}>
    <div className="row w-100" style={{'marginLeft':'0px','marginRight':'0px'}}>

<div className="col-md-12 col-12  hidden-sm hidden-xs" >
<div className="navbar-header" style={{display: "flex"}}>

    {
    localStorage.getItem("Logo") ?
    <a href={'/dashboard'} className="hidden-xs hidden-sm navbar-brand logo" style={{color: "red", fontSize: "24px", fontWeight: "bold", display: "flex"}}> <img src={localStorage.getItem("Logo")} style={{width: "120px",
    height: "auto",
    objectFit: "contain"}}/></a>
    : ''
}


<a href="/dashboard"  className="hidden-xs hidden-sm navbar-brand logo" style={{color: "red", fontSize: "24px", fontWeight: "bold",margin: "0 auto",display: "flex"}}>
<img src={this.state.piflogo} style={{width: "200px",
    height: "auto",
    objectFit: "contain"}}/>
</a>

 {LoginEmail && (
<a title="admin dashboard" target="_blank" href={`${pifurl}/authentication/sign-in?token=${localStorage.getItem('token')}`} className="hidden-xs hidden-sm navbar-brand" 

style={{color: "rgb(0, 47, 108)",  padding: "8px 16px",}}>
    

<div class="icon-combo">
  <i class="fas fa-user"></i>
  <i class="fas fa-cog"></i>
</div>

</a>
)}


 {LoginEmail && (
<a title="logout" href="/logout" className="hidden-xs hidden-sm navbar-brand " style={{color: "#002f6c", fontSize: "26px", fontWeight: "bold", marginTop: "10px"}}>

 <i class="fas fa-sign-out-alt"></i>



</a>
)}






</div>
</div>

   <div class="col-2"></div>

        <div className="col-md-6 col-6 hidden-lg hidden-md">
            <center>
                <a href="/" style={{ color: "red", fontSize: "24px", fontWeight: "bold" }} className=" hidden-lg hidden-md">
                    <img  src={this.state.piflogo} style={{ width: "200px" }} /> 
                </a>
            </center>
        </div>

   <div className="col-md-2 col-4 hidden-lg hidden-md">
            <div className="navbar-header" style={{display: "flex"}}>
                <a className="mt-5" id="mobile_btn" href="javascript:void(0);">
                  
                </a>
     {
    localStorage.getItem("Logo") ?
    <a href={localStorage.getItem("Logo")} className="hidden-xs hidden-sm navbar-brand logo" style={{color: "red", fontSize: "24px", fontWeight: "bold", display: "flex"}}> <img src={localStorage.getItem("Logo")} style={{width: "120px"}}/></a>
    : ''
}
                <a href="/" style={{ color: "red", fontSize: "24px", fontWeight: "bold" }} className="hidden-xs hidden-sm navbar-brand logo">


                   <img  src={localStorage.getItem("PifLogo")}  style={{ width: "200px" }}/> 
                </a>
      
 {LoginEmail && (
<a title="admin dashboard " target="_blank" href={`${pifurl}/authentication/sign-in?token=${localStorage.getItem('token')}`} className="mobile-user navbar-brand" 

style={{color: "rgb(0, 47, 108)",  padding: "8px 16px",}}>
    

<div class="icon-combo">
  <i class="fas fa-user"></i>
  <i class="fas fa-cog"></i>
</div>

</a>
)}



                {LoginEmail && (
<a href="/logout" className="navbar-brand mobile-sign-in" >
<i class="fas fa-sign-out-alt"></i>
</a>
)}
            </div>
        </div>

 
   
 
</div>		 


</nav>
</header>
        )
    }

}

export default withTranslation()(LoginHeader);