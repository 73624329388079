import React, { Component } from 'react';

class Logout extends Component {
  componentDidMount() {
    this.handleLogout();
  }

  handleLogout = () => {
    // Clear user session data (e.g., authentication tokens, user information)
    localStorage.removeItem('LoginEmail');
    localStorage.removeItem('LoginName');
    localStorage.removeItem('customer_id');
    localStorage.removeItem('Logo');
     localStorage.removeItem('PifLogo');
    localStorage.removeItem('token');
    localStorage.removeItem('CustomerType');
    localStorage.removeItem("DealerId");
    localStorage.removeItem("CuDefaultPercentage");
    localStorage.removeItem("CuPdfFileName");
    localStorage.removeItem("CompanyName");
    localStorage.removeItem('selectedBank');
    localStorage.removeItem("BankAddress");
    localStorage.removeItem('BankAddress2');
    localStorage.removeItem('BankState');
    localStorage.removeItem('BankZip');
    localStorage.removeItem('BankCity');

    localStorage.removeItem("TempPasswordStatus");
    localStorage.removeItem("PasswordAlertShown");
    // Redirect the user to the login page or any other page
    window.location.href = '/login'; // Example: Redirect to the login page
  };

  render() {
    return (
      <div>
        {/* Your component content */}
      </div>
    );
  }
}

export default Logout;
